<template>
    <v-container fluid fill-height>
        <v-layout align-top justify-center>
            <v-flex xs12 sm10 md10>
                <v-sheet color="white" elevation="20" class="pa-2">
                    <v-data-table :headers="headers[$vuetify.breakpoint.xsOnly]" :items="items"
                        no-data-text="No hay productos registrados" :items-per-page="-1" item-key="code"
                        mobile-breakpoint="0" group-by="category" show-group-by :search="search"
                        sort-by="category,product_name" class="table-cursor mt-3" @click:row="handleClick">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Maestro</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" hide-details append-icon="mdi-magnify" outlined
                                    placeholder="Buscar producto ..." clearable />
                                <v-spacer></v-spacer>
                                <v-btn fab icon color="success" @click="newForm">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>

                        <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
                            <v-avatar>
                                <v-img :src="getImagePhoto(item.account, '000000', item.picture)"></v-img>
                            </v-avatar>
                        </template>
                        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
                            <b>{{ item.product_name }}</b>
                            <span v-if="$vuetify.breakpoint.xsOnly">
                                <small> ({{ item.category }})</small><br />
                                <small> {{ item.size }} {{ item.uom }} </small>
                            </span>
                        </template>
                        <template v-slot:[`item.size`]="{ item }">
                            {{ item.size + " " + item.uom }} <br />
                        </template>

                        <template v-slot:[`item.cost`]="{ item }">
                            {{ "$ " + parseFloat(item.cost).toFixed(2) }}
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            {{ "$ " + parseFloat(item.price).toFixed(2) }}
                        </template>
                        <template v-slot:[`item.cost_uom`]="{ item }">
                            {{ "$ " + parseFloat(item.cost_uom).toFixed(2) }}
                        </template>
                        <template v-slot:[`item.price_uom`]="{ item }">
                            {{ "$ " + parseFloat(item.price_uom).toFixed(2) }}
                        </template>
                        <template v-slot:[`item.difference`]="{ item }">
                            {{ (((parseFloat(item.price_uom) - parseFloat(item.cost_uom)) / parseFloat(item.price_uom))
                                    *
                                    100).toFixed(2)
                            }}
                        </template>
                    </v-data-table>

                    <ProductForm :table="table" :item="item" :dialog="dialog" :newItem="newItem"
                        :categories="categories" :store="store" @close="dialog = false" :subcategories="subcategories"
                        :brands="brands" :suppliers="this.supplier_selected.code" @refresh="refresh()" />


                    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
                        <v-card color="primary" dark>
                            <v-card-text>
                                Cargando ...
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-sheet>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import ProductForm from "../components/RawMaterialForm.vue";
import createStore from "../utils/createStore.js";

export default {
    components: { ProductForm },
    data() {
        return {
            search_smart: "",
            s_expand: true,
            smart_width: 800,
            dialog_smart: false,
            smart_categories: [],
            smart_lst_selected: [],
            header_size: true,
            table: "raw_material",
            search: "",
            search_supplier: "",
            dialog_suppliers: true,
            errors: [],
            store: "",
            storesLst: [],
            valid: false,
            dialog: false,
            newItem: false,
            loading_status: false,
            byCategories: [],
            categories: [],
            subcategories: [],
            brands: [],
            suppliers: [],
            supplier_dialog: false,
            supplier_selected: createStore(),
            headers: {
                false: [
                    {
                        text: "",
                        align: "start",
                        sortable: false,
                        value: "addto",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "",
                        align: "center",
                        value: "picture",
                        width: 50,
                        groupable: false,
                    },
                    {
                        text: "Categoría",
                        align: "start",
                        sortable: true,
                        value: "category",
                        dataType: "text",
                        groupable: true,
                    },
                    {
                        text: "Marca",
                        align: "start",
                        sortable: true,
                        value: "brand",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "Producto",
                        align: "start",
                        sortable: true,
                        value: "product_name",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "Tamaño",
                        align: "center",
                        sortable: true,
                        value: "size",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "Pack",
                        align: "center",
                        sortable: true,
                        value: "pack",
                        dataType: "text",
                        groupable: false,
                    },
                    {
                        text: "Costo",
                        align: "end",
                        sortable: true,
                        value: "cost",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: " x UOM",
                        align: "end",
                        sortable: true,
                        value: "cost_uom",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: "Precio",
                        align: "end",
                        sortable: true,
                        value: "price",
                        dataType: "number",
                        groupable: false,
                    },
                    {
                        text: " x UOM",
                        align: "end",
                        sortable: true,
                        value: "price_uom",
                        dataType: "number",
                        groupable: false,
                    },
                     {
                        text: "Status",
                        align: "end",
                        sortable: true,
                        value: "active",
                        groupable: false,
                    },


                ],
                true: [
                    {
                        text: "",
                        align: "start",
                        sortable: false,
                        value: "addto",
                        dataType: "number",
                    },
                    {
                        text: "",
                        align: "center",
                        value: "picture",
                        width: 50,
                    },
                    {
                        text: "Producto",
                        align: "start",
                        sortable: true,
                        value: "product_name",
                        dataType: "text",
                    },
                    {
                        text: "Costo",
                        align: "end",
                        sortable: true,
                        value: "cost",
                        dataType: "number",
                    },
                    {
                        text: "",
                        align: "end",
                        sortable: false,
                        value: "action",
                        dataType: "number",
                    },
                ],
            },
            headers_suppliers: [
                {
                    text: "",
                    align: "start",
                    sortable: true,
                    value: "picture",
                    dataType: "text",
                    width: 40,
                },
                {
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "name",
                    dataType: "text",
                },
            ],
            items: [],
            smart_lst: [],
            smart_ctgry: [],
            item: createItem(),
            edit: false,
            catSelected: "",
        };
    },
    mounted() {
        this.storesLst = window.stores;
        //this.supplier.account="000000";
        // this.get_suppliers();
        this.get_smart_lst();

    },
    methods: {
        onResize() {
            if (window.innerWidth < 769) this.isMobile = true;
            else this.isMobile = false;
        },
        get_suppliers() {
            var qry = {
                account: this.$store.state.profile.account,
                table: "suppliers",
                filters: [],
            };
            this.loading_status = true;
            webserver("get_table", qry, (data) => {
                this.loading_status = false;
                this.suppliers = data;
            });
        },
        add_to_supplier(e) {
            this.item = { ...e };
            this.store = this.supplier_selected.code;
            this.item.store = this.supplier_selected.code;
            this.item.supplier = this.supplier_selected.code;
            this.valid = false;
            this.newItem = false;
            this.dialog = true;
        },
        get_smart_lst() {
            var qry = {
                account: this.$store.state.profile.account,
                table: "raw_material",
                filters: [{ field: "account", operator: "=", value: this.$store.state.profile.account },
                { field: "store", operator: "=", value: "000000" }],
            };
            this.loading_status = true;
            webserver("get_table", qry, (data) => {
                var ctgry = data.reduce(function (r, a) {
                    r[a.category] = r[a.category] || [];
                    r[a.category].push(a);
                    return r;
                }, Object.create(null));

                for (const [key] of Object.entries(ctgry)) {
                    ctgry[key].sort(function (a, b) {
                        var textA = a.product_name.toUpperCase();
                        var textB = b.product_name.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    });
                }
                this.smart_ctgry = ctgry;
                this.smart_categories = Object.keys(ctgry).sort();
                this.smart_lst_selected = data;

                //console.log(ctgry, data);
                this.loading_status = false;
                this.items = data;

            });
        },
        handleClick(data) {
            this.store = data.store;
            this.item = { ...data };
            this.newItem = false;
            this.dialog = true;
        },
        newForm() {
            this.item = createItem();
            this.store = "000000";
            this.item.account = this.$store.state.profile.account;
            this.item.store = "000000";
            this.item.supplier = "SCP";
            this.valid = false;
            this.newItem = true;
            this.dialog = true;
        },
        edit_smart_lst(data) {
            this.item = createItem();
            this.item = { ...data };
            this.store = "000000";
            this.item.account = this.$store.state.profile.account;
            this.item.store = "000000";
            this.item.supplier = "SCP";
            this.valid = false;
            this.newItem = false;
            this.dialog = true;
        },
        refresh() {
            this.dialog = false;
            this.get_smart_lst();
            //this.update_selected();
        },
        getImagePhoto: function (account, code, src) {
            var url =
                "https://mayorista.app/photos/" + account + "/000000/" + src;
            return url;
        },
        changeMenu(e) {
            this.search_smart = "";
            this.smart_lst_selected = this.smart_ctgry[e];
            //console.log(this.smart_lst_selected, this.smart_ctgry, e);
        },
    },
    watch: {
        s_expand: function (e) {
            //console.log(e);
            if (e) {
                this.smart_width = 800;
            } else {
                this.smart_width = 450;
            }
        },
    },
};
</script>

<style>
.file-input {
    display: none;
}

.toptop {
    top: 70px !important;
}

.centered-input input {
    text-align: center;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}

.v-expansion-panel-header {
    padding: 0px !important;
}

.float {
    position: fixed;
    width: 40px;
    height: 40px;
    top: 70px;
    right: 20px;
    background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 2;
}
</style>
