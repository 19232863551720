var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-top":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":""}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"color":"white","elevation":"20"}},[_c('v-data-table',{staticClass:"table-cursor mt-3",attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.items,"no-data-text":"No hay productos registrados","items-per-page":-1,"item-key":"code","mobile-breakpoint":"0","group-by":"category","show-group-by":"","search":_vm.search,"sort-by":"category,product_name"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Maestro")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","placeholder":"Buscar producto ...","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","icon":"","color":"success"},on:{"click":_vm.newForm}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},(_vm.item.picture !== '')?{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, '000000', item.picture)}})],1)]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(" ("+_vm._s(item.category)+")")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.uom)+" ")])]):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" "),_c('br')]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost).toFixed(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toFixed(2))+" ")]}},{key:"item.cost_uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost_uom).toFixed(2))+" ")]}},{key:"item.price_uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price_uom).toFixed(2))+" ")]}},{key:"item.difference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((((parseFloat(item.price_uom) - parseFloat(item.cost_uom)) / parseFloat(item.price_uom)) * 100).toFixed(2))+" ")]}}],null,true)}),_c('ProductForm',{attrs:{"table":_vm.table,"item":_vm.item,"dialog":_vm.dialog,"newItem":_vm.newItem,"categories":_vm.categories,"store":_vm.store,"subcategories":_vm.subcategories,"brands":_vm.brands,"suppliers":this.supplier_selected.code},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}}),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }